import { EventEmitter, Injectable } from '@angular/core';
import { mergedGates, paymentCharge } from '../interfaces/mydata.interfaces';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class GateSelectService {
  initialval:mergedGates ={
    Amount: 0,
    Currency: '',
    PaymentMethod: '',
    cardImg:'',
    GatewayType:''
  }
  select = new BehaviorSubject<mergedGates|paymentCharge>(this.initialval);
 


}

